import {ApolloClient, InMemoryCache, HttpLink, from} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import type {ServerError} from "@apollo/client/link/utils";

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_GATEWAY_URL || 'https://desc.team'}/finance/api/graphql`
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if ((networkError as ServerError)?.statusCode === 401){
            console.log('[Network error]: redirect to login');
            window.location.pathname = '/signin';
        }
    }
});

const cache = new InMemoryCache({
    typePolicies: {
        Journal: {
            keyFields: ["id"],
        },
    },
});

const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: cache,
});

export default client;