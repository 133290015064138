import {gql} from "@apollo/client";

export const TRANSACTION = gql`
    fragment TransactionFragment on Transaction {
        id
        description
        bookingDate
        valueDate
        endToEndId
        amountCurrency
        amountValue
        creditorName
        creditorBic
        creditorIban
        debtorName
        debtorBic
        debtorIban
        mandateId
        vault {
            id
            name
        }
        status {
            id
            name
            type
        }
    }
`;

export const JOURNAL = gql`
    fragment JournalFragment on Journal {
        id
        peerName
        description
        valueDate
        amountValue
        amountCurrency
        account {
            id
            name
            fullName
            description
        }
        source {
            id
            key
            name
            description
        }
    }
`;

export const GET_TRANSACTIONS = gql`
    ${TRANSACTION}
    query getTransactionsPage($filter: Filter, $paging: OffsetPaging) {
        transactionsPage (
            filter: $filter
            paging: $paging
        ) {
            totalCount
            nodes  {
                ...TransactionFragment
            }
        }
    }
`;

export const GET_JOURNAL_BY_FILTER = gql`
    ${TRANSACTION}
    ${JOURNAL}
    query getJournalsPage($filter: Filter, $paging: OffsetPaging) {
        journalsPage (
            filter: $filter
            paging: $paging
        ) {
            totalCount
            aggregate {
                sum
            }
            nodes  {
                ...JournalFragment
                transaction {
                    ...TransactionFragment
                }
            }
        }
    }
`;

export const GET_JOURNALS = gql`
    ${JOURNAL}
    query getJournals($transactionId: ID) {
        journals (
            transactionId: $transactionId
        ) {
            ...JournalFragment
        }
    }
`;

export const SET_JOURNALS = gql`
    ${JOURNAL}
    mutation createOrUpdateJournal($journal: JournalInput) {
        journal (
            journal: $journal
        ) {
            ...JournalFragment
        }
    }
`;

export const GET_ACCOUNTS = gql`
    query getAccounts {
        accounts  {
            id
            name
            fullName
            description
        }
    }
`;

export const GET_SOURCES = gql`
    query getSources {
        sources  {
            id
            key
            name
            description
        }
    }
`;

export const GET_STATUSES = gql`
    query getStatuses {
        statuses  {
            id
            name
            type
        }
    }
`;

export const ADJUST_TRANSACTION = gql`
    ${TRANSACTION}
    mutation adjustTransaction($id: ID) {
        adjustTransaction (
            id: $id
        ) {
            ...TransactionFragment
        }
    }
`;
