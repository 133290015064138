import React from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {Divider, Layout, Typography} from "antd";
import Nav from "./Nav";
import {LogoIcon} from "./icon/LogoIcon";
import {CurrentUser} from "./user/CurrentUser";

export const LayoutComponent: React.FC = () => {

  const location = useLocation();

  const logoStyles: React.CSSProperties = {
    padding: "0px 8px 0 0",
  };


  return (
    <Layout style={{height: "100vh"}}>
      <Layout.Header style={{display: "flex", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10, backgroundColor: "#ffffff"}}>
        <Link to="/" style={{display: "flex", alignItems: "center", textDecorationLine: "none", userSelect: "none"}}>
          <LogoIcon style={logoStyles}/>
          <Typography.Text strong id="headerTitle" style={{fontSize: 20}}>DESC - Quarteera e. V.</Typography.Text>
        </Link>
        {!location.pathname.startsWith("/signin") && <CurrentUser/>}
      </Layout.Header>
      <Divider style={{margin: '8px 0'}}/>
        <Layout>
          {!location.pathname.startsWith("/signin") &&
          <Layout.Sider width="200px" collapsible breakpoint="lg" theme="light" collapsedWidth={60}>
            <Nav/>
          </Layout.Sider>
          }
          <Layout.Content style={{overflow: "auto"}}>
            <div className="container px-3">
              <Outlet/>
            </div>
          </Layout.Content>
        </Layout>
    </Layout>
  );
};

