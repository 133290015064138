import {Button, Layout, Space, Typography} from "antd";
import Page from "./Page";
import {LogoIcon} from "../icon/LogoIcon";
import React from "react";
import {GoogleIcon} from "../icon/GoogleIcon";

const logoStyles: React.CSSProperties = {
  padding: "0px 8px 0 0",
};

const LOGIN_URL = `${process.env.REACT_APP_GATEWAY_URL || 'https://desc.team'}/oauth2/authorization/google`

const LoginForm = () => (
    <Page title="SignIn" description="Please sign in" divider={false}>
      <Layout
        style={{
          height: "calc(100vh - 82px)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space direction="vertical" align="center" size="large">
          <Space direction="vertical" align="center" size="large">
            <LogoIcon style={logoStyles}/>
            <Typography.Title level={3}>Quarteera e. V.</Typography.Title>
          </Space>
          <Button
            size="large"
            icon={<GoogleIcon
              style={{
                height: '24px',
                width: '24px',
                fontSize: 24,
                lineHeight: 0,
              }}
            />}
            onClick={() => window.location.replace(LOGIN_URL)}
          >
            Sign in with Google
          </Button>
        </Space>
      </Layout>
    </Page>
);

export default LoginForm;