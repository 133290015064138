import React from "react";

import {AvatarProps, Typography} from "antd";
import {Avatar} from "antd";
import {getRandomColorFromString} from "../../utils/get-random-color";
import {getNameInitials} from "../../utils/get-name-initials";


type Props = AvatarProps & {
  name?: string;
};

const CustomAvatarComponent = ({name = "", style, ...rest}: Props) => {
  return (
    <>
      <Avatar
        alt={name}
        size="default"
        style={{
          backgroundColor: rest?.src
            ? "transparent"
            : getRandomColorFromString(name),
          display: "flex",
          alignItems: "center",
          border: "none",
          ...style,
        }}
        {...rest}
      >
        {getNameInitials(name)}
      </Avatar>
    </>
  );
};

export const CustomAvatar = React.memo(
  CustomAvatarComponent,
  (prevProps, nextProps) => {
    return prevProps.name === nextProps.name && prevProps.src === nextProps.src;
  },
);
