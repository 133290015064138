import {Layout, Space, Typography} from "antd";
import Page from "./Page";
import {LogoIcon} from "../icon/LogoIcon";
import React from "react";

const logoStyles: React.CSSProperties = {
  padding: "0px 8px 0 0",
};

const Home = () => (
    <Page title="Home" description="Welcome to application." divider={false}>
      <Layout
        style={{
          height: "calc(100vh - 82px)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space direction="vertical" align="center" size="large">
          <LogoIcon style={logoStyles}/>
          <Typography.Title level={3}>Welcome To Dashboard DESC Team.</Typography.Title>
        </Space>
      </Layout>

    </Page>
);

export default Home;